.gamemodes-container {
  width: 100%;
  height: max-content;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.gamemodes-container01 {
  width: 100%;
  height: 29px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.gamemodes-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: max-content;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
}
.gamemodes-container03 {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.gamemodes-container04 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1000px;
  min-width: 600px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-twounits);
  min-height: 400px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-threeunits);
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(82, 195, 92) 0.00%,rgba(0, 0, 0, 0.5) 100.00%),url("/frame%2023-1000w.png");
  background-repeat: top lefttop left,no-repeat;
  background-position: center;
}
.gamemodes-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  min-height: 100px;
  align-items: flex-start;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(28, 145, 54, 0.75) 0.00%,rgba(28, 145, 54, 0) 99.00%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 32;
  border-bottom-right-radius: 32;
}
.gamemodes-text02 {
  font-style: normal;
  font-weight: 400;
}
.gamemodes-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: flex-start;
}
.gamemodes-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.gamemodes-text03 {
  font-style: normal;
  font-weight: 700;
}
.gamemodes-link {
  display: contents;
}
.gamemodes-container08 {
  width: 65px;
  height: 65px;
  display: flex;
  transition: 0.3s;
  align-items: flex-end;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  background-size: cover;
  justify-content: flex-end;
  text-decoration: none;
  background-image: url("/external/vk_compact_logo_(2021-present).svg-200h-200h.png");
  background-position: center;
}
.gamemodes-container08:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.gamemodes-image {
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.gamemodes-link1 {
  display: contents;
}
.gamemodes-container09 {
  width: 65px;
  height: 65px;
  display: flex;
  transition: 0.3s;
  align-items: flex-end;
  background-size: cover;
  justify-content: flex-end;
  text-decoration: none;
  background-image: url("/external/untitled-1-200h-200h.png");
  background-position: center;
}
.gamemodes-container09:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.gamemodes-image1 {
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.gamemodes-container10 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1000px;
  min-width: 600px;
  align-self: stretch;
  margin-top: var(--dl-space-space-twounits);
  min-height: 400px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-threeunits);
  padding-left: 0px;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(157, 31, 82) 0.00%,rgba(0, 0, 0, 0.5) 100.00%),url("/frame%2024-1000w.png");
  background-repeat: bottom,center;
  background-position: center,bottom;
}
.gamemodes-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  min-height: 100px;
  align-items: flex-start;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(25, 118, 210, 0.75) 0.00%,rgba(25, 118, 210, 0) 100.00%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 32;
  border-bottom-right-radius: 32;
}
.gamemodes-text05 {
  font-style: normal;
  font-weight: 400;
}
.gamemodes-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: flex-start;
}
.gamemodes-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.gamemodes-text06 {
  font-style: normal;
  font-weight: 700;
}
.gamemodes-link2 {
  display: contents;
}
.gamemodes-container14 {
  width: 65px;
  height: 65px;
  display: flex;
  transition: 0.3s;
  align-items: flex-end;
  margin-left: var(--dl-space-space-unit);
  background-size: cover;
  justify-content: flex-end;
  text-decoration: none;
  background-image: url("/external/untitled-1-200h-200h.png");
  background-position: center;
}
.gamemodes-container14:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.gamemodes-image2 {
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.gamemodes-container15 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1000px;
  min-width: 600px;
  align-self: stretch;
  margin-top: var(--dl-space-space-twounits);
  min-height: 400px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-threeunits);
  padding-left: 0px;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(177, 122, 44) 0.00%,rgba(0, 0, 0, 0) 100.00%),url("/frame%2026-1000w.png");
  background-repeat: center,no-repeat;
  background-position: center;
}
.gamemodes-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  min-height: 100px;
  align-items: flex-start;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(177, 44, 44, 0.75) 0.00%,rgba(177, 44, 44, 0) 100.00%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 32;
  border-bottom-right-radius: 32;
}
.gamemodes-text08 {
  font-style: normal;
  font-weight: 400;
}
.gamemodes-container17 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1000px;
  min-width: 600px;
  align-self: stretch;
  margin-top: var(--dl-space-space-twounits);
  min-height: 400px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-threeunits);
  padding-left: 0px;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(157, 119, 75) 0.00%,rgba(0, 0, 0, 0.2) 100.00%),url("/frame%2025-1000w.png");
  background-repeat: top lefttop left,no-repeat;
  background-position: center;
}
.gamemodes-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  min-height: 100px;
  align-items: flex-start;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(191, 138, 2, 0.75) 0.00%,rgba(191, 138, 2, 0) 100.00%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 32;
  border-bottom-right-radius: 32;
}
.gamemodes-text10 {
  font-style: normal;
  font-weight: 400;
}
.gamemodes-container19 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1000px;
  min-width: 600px;
  align-self: stretch;
  margin-top: var(--dl-space-space-twounits);
  min-height: 400px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-threeunits);
  padding-left: 0px;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(45, 140, 212) 0.00%,rgba(0, 0, 0, 0.5) 100.00%),url("/frame%2028-1000w.png");
  background-repeat: top lefttop left,no-repeat;
  background-position: center;
}
.gamemodes-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  min-height: 100px;
  align-items: flex-start;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(109, 123, 134, 0.75) 0.00%,rgba(109, 123, 134, 0) 100.00%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 32;
  border-bottom-right-radius: 32;
}
.gamemodes-text12 {
  font-style: normal;
  font-weight: 400;
}
.gamemodes-text13 {
  font-size: 28px;
  margin-top: 0px;
  margin-left: var(--dl-space-space-twounits);
}
.gamemodes-text14 {
  font-size: 28px;
  margin-top: 0px;
  margin-left: var(--dl-space-space-twounits);
}
.gamemodes-text15 {
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 800;
}
.gamemodes-text16 {
  font-size: 20px;
}
.gamemodes-text17 {
  font-size: 28px;
  margin-top: 0px;
  margin-left: var(--dl-space-space-twounits);
}
.gamemodes-text18 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat";
}
.gamemodes-text19 {
  font-size: 20px;
}
.gamemodes-text20 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat";
}
.gamemodes-text21 {
  font-size: 28px;
  margin-top: 0px;
  margin-left: var(--dl-space-space-twounits);
}
.gamemodes-text22 {
  font-size: 28px;
  margin-top: 0px;
  margin-left: var(--dl-space-space-twounits);
}
.gamemodes-text23 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat";
}
.gamemodes-text24 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.gamemodes-text25 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  text-align: left;
  font-family: "Montserrat";
  border-radius: 32px;
  border-top-right-radius: 0;
}
.gamemodes-root-class-name {
  background-color: #1e1e1e;
}


@media(max-width: 1321px) {
  .gamemodes-container {
    padding: var(--dl-space-space-unit);
  }
  .gamemodes-container04 {
    max-width: 1000px;
    min-width: 400px;
    min-height: 300px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .gamemodes-container10 {
    max-width: 1000px;
    min-width: 400px;
    min-height: 300px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .gamemodes-container15 {
    max-width: 1000px;
    min-width: 400px;
    min-height: 300px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .gamemodes-container17 {
    max-width: 1000px;
    min-width: 400px;
    min-height: 300px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .gamemodes-container19 {
    max-width: 1000px;
    min-width: 400px;
    min-height: 300px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 1200px) {
  .gamemodes-container {
    padding: var(--dl-space-space-twounits);
  }
  .gamemodes-container04 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    background-repeat: bottom,no-repeat;
    background-position: top lefttop left,center;
  }
  .gamemodes-container10 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    background-repeat: bottom,no-repeat;
    background-position: top lefttop left,center;
  }
  .gamemodes-container15 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    background-repeat: bottom,no-repeat;
    background-position: top lefttop left,center;
  }
  .gamemodes-container17 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    background-repeat: bottom,no-repeat;
    background-position: top lefttop left,center;
  }
  .gamemodes-container19 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    background-repeat: bottom,no-repeat;
    background-position: top lefttop left,center;
  }
  .gamemodes-text15 {
    font-size: 28;
  }
}
@media(max-width: 991px) {
  .gamemodes-container {
    padding: var(--dl-space-space-unit);
  }
  .gamemodes-container04 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .gamemodes-container10 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .gamemodes-container15 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .gamemodes-container17 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .gamemodes-container19 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .gamemodes-text14 {
    font-style: normal;
    font-weight: 700;
  }
  .gamemodes-text17 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .gamemodes-text21 {
    font-style: inherit;
    font-family: Montserrat;
    font-weight: 700;
  }
  .gamemodes-text22 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
}
@media(max-width: 767px) {
  .gamemodes-container {
    padding: var(--dl-space-space-unit);
  }
  .gamemodes-container04 {
    min-width: 400px;
    margin-top: var(--dl-space-space-halfunit);
    min-height: 250px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .gamemodes-container10 {
    min-width: 400px;
    margin-top: var(--dl-space-space-halfunit);
    min-height: 250px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .gamemodes-container15 {
    min-width: 400px;
    margin-top: var(--dl-space-space-halfunit);
    min-height: 250px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .gamemodes-container17 {
    min-width: 400px;
    margin-top: var(--dl-space-space-halfunit);
    min-height: 250px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .gamemodes-container19 {
    min-width: 400px;
    margin-top: var(--dl-space-space-halfunit);
    min-height: 250px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .gamemodes-text13 {
    font-size: 24px;
  }
  .gamemodes-text14 {
    font-size: 24px;
  }
  .gamemodes-text17 {
    font-size: 24px;
  }
  .gamemodes-text18 {
    font-size: 16px;
  }
  .gamemodes-text20 {
    font-size: 16px;
  }
  .gamemodes-text21 {
    font-size: 24px;
  }
  .gamemodes-text22 {
    font-size: 24px;
  }
  .gamemodes-text23 {
    font-size: 16px;
  }
  .gamemodes-text24 {
    font-size: 16px;
  }
  .gamemodes-text25 {
    font-size: 16px;
  }
}
@media(max-width: 479px) {
  .gamemodes-container {
    padding: var(--dl-space-space-halfunit);
  }
  .gamemodes-container04 {
    min-width: 300px;
    min-height: 200px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    background-size: autoauto,cover;
    background-repeat: top lefttop left;
    background-position: top lefttop left,center;
  }
  .gamemodes-container06 {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }
  .gamemodes-container07 {
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }
  .gamemodes-container08 {
    width: 32px;
    height: 32px;
    margin-top: 0px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .gamemodes-image {
    width: 8px;
    height: 8px;
  }
  .gamemodes-container09 {
    width: 32px;
    height: 32px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .gamemodes-image1 {
    width: 8px;
    height: 8px;
  }
  .gamemodes-container10 {
    min-width: 300px;
    min-height: 200px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    background-repeat: top lefttop left;
  }
  .gamemodes-container14 {
    width: 32px;
    height: 32px;
  }
  .gamemodes-image2 {
    width: 8px;
    height: 8px;
  }
  .gamemodes-container15 {
    min-width: 300px;
    min-height: 200px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    background-repeat: bottom;
  }
  .gamemodes-container17 {
    min-width: 300px;
    min-height: 200px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    background-repeat: top lefttop left;
    background-position: top lefttop left,center;
  }
  .gamemodes-container19 {
    min-width: 300px;
    min-height: 200px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    background-repeat: top lefttop left;
  }
  .gamemodes-text13 {
    font-size: 20px;
  }
  .gamemodes-text14 {
    font-size: 20px;
  }
  .gamemodes-text15 {
    font-size: 22;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 800;
  }
  .gamemodes-text16 {
    font-size: 14;
  }
  .gamemodes-text17 {
    font-size: 20px;
  }
  .gamemodes-text18 {
    font-size: 12;
  }
  .gamemodes-text19 {
    font-size: 14;
  }
  .gamemodes-text20 {
    font-size: 12;
  }
  .gamemodes-text21 {
    font-size: 20px;
  }
  .gamemodes-text22 {
    font-size: 20px;
  }
  .gamemodes-text23 {
    font-size: 12;
  }
  .gamemodes-text24 {
    font-size: 12;
  }
  .gamemodes-text25 {
    font-size: 12;
  }
}
