.join-header26 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: max-content;
  max-width: auto;
  min-height: auto;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/frame%2029-1500w.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.join-container {
  width: 100%;
  height: 120px;
  display: flex;
  margin-top: -1px;
  margin-bottom: 0px;
  flex-direction: column;
  background-image: linear-gradient(180deg, rgb(25, 25, 25) 1.00%,rgba(130, 130, 130, 0) 100.00%,rgba(130, 130, 130, 0) 100.00%);
}
.join-container01 {
  flex: 0 0 auto;
  width: max-content;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  align-self: flex-start;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  border-radius: 32px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.75);
}
.join-container02 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.join-link {
  transition: 0.3s;
  text-decoration: none;
}

.join-container03 {
  flex: 0 0 auto;
  width: max-content;
  display: flex;
  max-width: 55%;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-unit);
  border-radius: 32px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #000000;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.join-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  max-width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.join-image {
  width: 48px;
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
}
.join-container05 {
  flex: 0 0 auto;
  width: max-content;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 100%;
  align-items: center;
}
.join-container06 {
  flex: 0 0 auto;
  width: max-content;
  display: flex;
  max-width: 85%;
  align-items: flex-start;
  flex-direction: column;
}
.join-text03 {
  font-style: normal;
  font-weight: 400;
}
.join-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  max-width: 100%;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.join-image1 {
  width: 48px;
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
}
.join-container08 {
  flex: 0 0 auto;
  width: max-content;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 100%;
  align-items: center;
}
.join-container09 {
  flex: 0 0 auto;
  width: max-content;
  display: flex;
  max-width: 85%;
  align-items: flex-start;
  flex-direction: column;
}
.join-text05 {
  font-style: normal;
  font-weight: 400;
}
.join-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  max-width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.join-image2 {
  width: 48px;
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
}
.join-container11 {
  flex: 0 0 auto;
  width: max-content;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 100%;
  align-items: center;
}
.join-container12 {
  flex: 0 0 auto;
  width: max-content;
  display: flex;
  max-width: 85%;
  align-items: flex-start;
  flex-direction: column;
}
.join-text07 {
  font-style: normal;
  font-weight: 400;
}
.join-container13 {
  width: 100%;
  height: 120px;
  display: flex;
  margin-bottom: -2px;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(25, 25, 25) 1.00%,rgba(25, 25, 25, 0) 100.00%,rgba(25, 25, 25, 0) 100.00%);
}
.join-text08 {
  font-size: 16px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat";
  font-weight: 400;
}
.join-text10 {
  font-size: 16px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat";
  font-weight: 400;
}
.join-text12 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 54px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.join-text13 {
  padding: var(--dl-space-space-unit);
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 500;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-primary1);
}
.join-text13:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.join-text14 {
  font-size: 16px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 400;
}
.join-text15 {
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-twounits);
}


@media(max-width: 1321px) {
  .join-text12 {
    color: var(--dl-color-theme-primary2);
    font-size: 40;
  }
  .join-text13 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .join-text15 {
    font-size: 18px;
    font-weight: 500;
  }
}
@media(max-width: 1200px) {
  .join-container01 {
    max-width: 60%;
    align-items: center;
    justify-content: center;
  }
  .join-text12 {
    color: var(--dl-color-theme-primary2);
    font-size: 40;
  }
  .join-text13 {
    font-size: 12;
    font-style: normal;
    font-weight: 500;
  }
}
@media(max-width: 991px) {
  .join-container01 {
    max-width: 75%;
    margin-left: var(--dl-space-space-unit);
  }
  .join-text08 {
    font-size: 14;
  }
  .join-text10 {
    font-size: 14;
  }
  .join-text12 {
    color: var(--dl-color-theme-primary2);
    font-size: 32px;
  }
  .join-text14 {
    font-size: 14;
  }
}
@media(max-width: 767px) {
  .join-header26 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .join-container {
    height: 80px;
  }
  .join-container01 {
    max-width: 95%;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .join-container02 {
    width: 100%;
    height: auto;
  }
  .join-container03 {
    max-width: 100%;
    background-color: transparent;
  }
  .join-container13 {
    height: 80px;
  }
  .join-text12 {
    font-size: 42;
  }
  .join-text13 {
    font-size: 18;
  }
  .join-text15 {
    font-size: 18;
  }
}
@media(max-width: 479px) {
  .join-header26 {
    padding: 0px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .join-container01 {
    max-width: auto;
    align-items: center;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .join-container02 {
    width: 80%;
    height: auto;
    padding: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .join-container03 {
    max-width: 100%;
    align-items: center;
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-left-radius: 32;
    border-bottom-right-radius: 32;
  }
  .join-image {
    width: 24;
    height: 24;
  }
  .join-container05 {
    padding: var(--dl-space-space-halfunit);
  }
  .join-image1 {
    width: 24;
    height: 24;
  }
  .join-container08 {
    padding: var(--dl-space-space-halfunit);
  }
  .join-image2 {
    width: 24;
    height: 24;
  }
  .join-container11 {
    padding: var(--dl-space-space-halfunit);
  }
  .join-text08 {
    font-size: 12;
    margin-top: 6px;
  }
  .join-text09 {
    font-size: 16;
  }
  .join-text10 {
    font-size: 12;
    margin-top: 6px;
  }
  .join-text11 {
    font-size: 16;
  }
  .join-text12 {
    color: var(--dl-color-theme-primary2);
    font-size: 28;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .join-text13 {
    padding: 6px;
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .join-text14 {
    font-size: 12;
    margin-top: 6px;
  }
  .join-text15 {
    font-size: 14;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .join-text16 {
    font-size: 16;
  }
}
