.about-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-text {
  color: rgb(255, 255, 255);
}
.about-text01 {
  color: #000000;
  width: fit-content;
  height: auto;
  padding: var(--dl-space-space-oneandhalfunits);
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  font-family: "Montserrat";
  font-weight: 700;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: rgb(255, 255, 255);
}
.about-text01:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.about-text02 {
  font-style: normal;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 800;
}
.about-text03 {
  color: #2df059;
}
.about-text06 {
  font-size: 18px;
  font-style: normal;
  margin-top: 0px;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-text07 {
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 800;
}
.about-text08 {
  font-size: 28px;
  margin-left: var(--dl-space-space-unit);
}
.about-text09 {
  font-size: 28px;
  margin-left: var(--dl-space-space-unit);
}
.about-text10 {
  font-size: 28px;
  margin-left: var(--dl-space-space-unit);
}
.about-text11 {
  font-size: 28px;
  margin-left: var(--dl-space-space-unit);
}
.about-text12 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  text-align: left;
  font-family: "Montserrat";
  border-radius: 32px;
  border-top-right-radius: 0;
}
.about-text18 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat";
}
.about-text19 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat";
}
.about-text20 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat";
}
.about-text21 {
  font-size: 28px;
  margin-left: var(--dl-space-space-unit);
}
.about-text22 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.about-text23 {
  font-size: 20px;
}
.about-text24 {
  font-size: 20px;
}
.about-text25 {
  height: auto;
  font-size: 18px;
  margin-left: -48px;
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.about-text26 {
  height: auto;
  font-size: 18px;
  margin-left: -48px;
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.about-text29 {
  height: auto;
  font-size: 18px;
  margin-left: -48px;
  padding-top: var(--dl-space-space-unit);
  margin-right: 0px;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(0, 0, 0);
}
.about-text30 {
  margin-top: var(--dl-space-space-unit);
}
.about-text31 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.about-text32 {
  margin-top: var(--dl-space-space-unit);
}
.about-text33 {
  font-size: 16px;
}
.about-text34 {
  font-style: normal;
  font-weight: 400;
}
.about-text35 {
  font-style: normal;
  font-weight: 400;
}
.about-text36 {
  font-style: normal;
  font-weight: 400;
}
.about-text37 {
  color: #8c8c8c;
  font-style: normal;
  font-weight: 400;
}
.about-text39 {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text40 {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text41 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.about-text42 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.about-text43 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
}
.about-text44 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 800;
}
.about-text45 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 800;
}
.about-text46 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.about-text47 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.about-text48 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.about-text49 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.about-text50 {
  font-family: "Montserrat";
  font-weight: 600;
}
.about-text51 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 54px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-text52 {
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-text53 {
  padding: var(--dl-space-space-unit);
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 500;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-primary1);
}
.about-text53:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.about-text55 {
  font-size: 16px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 400;
}
.about-text57 {
  font-size: 16px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat";
  font-weight: 400;
}
.about-text59 {
  font-size: 16px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat";
  font-weight: 400;
}
.about-text60 {
  color: rgb(255, 255, 255);
}
@media(max-width: 1321px) {
  .about-text26 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text30 {
    font-size: 20px;
  }
  .about-text31 {
    font-size: 14px;
  }
  .about-text32 {
    font-size: 20px;
  }
  .about-text33 {
    font-size: 14px;
  }
  .about-text39 {
    font-size: 20px;
  }
  .about-text40 {
    font-size: 20px;
  }
  .about-text41 {
    font-size: 14px;
  }
  .about-text42 {
    font-size: 14px;
  }
  .about-text46 {
    font-size: 16px;
  }
  .about-text47 {
    font-size: 16px;
  }
  .about-text48 {
    font-size: 16px;
  }
  .about-text49 {
    font-size: 16px;
  }
  .about-text50 {
    font-size: 16px;
  }
  .about-text51 {
    color: var(--dl-color-theme-primary2);
    font-size: 40;
  }
  .about-text52 {
    font-size: 18px;
    font-weight: 500;
  }
  .about-text53 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
}
@media(max-width: 1200px) {
  .about-text07 {
    font-size: 28;
  }
  .about-text25 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text26 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text29 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text51 {
    color: var(--dl-color-theme-primary2);
    font-size: 40;
  }
  .about-text53 {
    font-size: 12;
    font-style: normal;
    font-weight: 500;
  }
}
@media(max-width: 991px) {
  .about-text {
    color: rgb(255, 255, 255);
  }
  .about-text01 {
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .about-text02 {
    font-size: 36px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 800;
  }
  .about-text03 {
    color: #2df059;
  }
  .about-text06 {
    font-size: 18;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
  }
  .about-text08 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .about-text09 {
    font-style: normal;
    font-weight: 700;
  }
  .about-text10 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .about-text11 {
    font-style: inherit;
    font-family: Montserrat;
    font-weight: 700;
  }
  .about-text51 {
    color: var(--dl-color-theme-primary2);
    font-size: 32px;
  }
  .about-text55 {
    font-size: 14;
  }
  .about-text57 {
    font-size: 14;
  }
  .about-text59 {
    font-size: 14;
  }
  .about-text60 {
    color: rgb(255, 255, 255);
  }
}
@media(max-width: 767px) {
  .about-text01 {
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-family: Montserrat;
    font-weight: 700;
  }
  .about-text08 {
    font-size: 24px;
  }
  .about-text09 {
    font-size: 24px;
  }
  .about-text10 {
    font-size: 24px;
  }
  .about-text11 {
    font-size: 24px;
  }
  .about-text12 {
    font-size: 16px;
  }
  .about-text18 {
    font-size: 16px;
  }
  .about-text19 {
    font-size: 16px;
  }
  .about-text20 {
    font-size: 16px;
  }
  .about-text21 {
    font-size: 24px;
  }
  .about-text22 {
    font-size: 16px;
  }
  .about-text25 {
    font-size: 16;
  }
  .about-text26 {
    font-size: 16;
  }
  .about-text29 {
    font-size: 16;
  }
  .about-text43 {
    font-size: 20;
  }
  .about-text44 {
    font-size: 24;
  }
  .about-text45 {
    font-size: 24;
  }
  .about-text46 {
    font-size: 14;
  }
  .about-text47 {
    font-size: 14;
  }
  .about-text48 {
    font-size: 14;
  }
  .about-text49 {
    font-size: 14;
  }
  .about-text50 {
    font-size: 14;
  }
  .about-text51 {
    font-size: 42;
  }
  .about-text52 {
    font-size: 18;
  }
  .about-text53 {
    font-size: 18;
  }
}
@media(max-width: 479px) {
  .about-text {
    font-size: 12;
  }
  .about-text01 {
    padding: var(--dl-space-space-unit);
    font-size: 12px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    font-family: Montserrat;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .about-text02 {
    font-size: 20;
  }
  .about-text06 {
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
  }
  .about-text07 {
    font-size: 22;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 800;
  }
  .about-text08 {
    font-size: 20px;
  }
  .about-text09 {
    font-size: 20px;
  }
  .about-text10 {
    font-size: 20px;
  }
  .about-text11 {
    font-size: 20px;
  }
  .about-text12 {
    font-size: 12;
  }
  .about-text18 {
    font-size: 12;
  }
  .about-text19 {
    font-size: 12;
  }
  .about-text20 {
    font-size: 12;
  }
  .about-text21 {
    font-size: 20px;
  }
  .about-text22 {
    font-size: 12;
  }
  .about-text23 {
    font-size: 14;
  }
  .about-text24 {
    font-size: 14;
  }
  .about-text25 {
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text26 {
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text29 {
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text43 {
    font-size: 16;
  }
  .about-text44 {
    font-size: 20;
  }
  .about-text45 {
    font-size: 20;
  }
  .about-text46 {
    font-size: 12;
  }
  .about-text47 {
    font-size: 12;
  }
  .about-text48 {
    font-size: 12;
  }
  .about-text49 {
    font-size: 12;
  }
  .about-text50 {
    font-size: 12;
  }
  .about-text51 {
    color: var(--dl-color-theme-primary2);
    font-size: 28;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text52 {
    font-size: 14;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text53 {
    padding: 6px;
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text54 {
    font-size: 16;
  }
  .about-text55 {
    font-size: 12;
    margin-top: 6px;
  }
  .about-text56 {
    font-size: 16;
  }
  .about-text57 {
    font-size: 12;
    margin-top: 6px;
  }
  .about-text58 {
    font-size: 16;
  }
  .about-text59 {
    font-size: 12;
    margin-top: 6px;
  }
  .about-text60 {
    font-size: 12;
  }
}
