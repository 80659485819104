.faq-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 2560px;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.faq-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: column;
}
.faq-container {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
}
.faq-section-title {
  align-self: flex-start;
  align-items: flex-start;
}
.faq-content {
  align-self: stretch;
  align-items: flex-start;
}
.faq-list {
  max-width: 100%;
  align-self: flex-start;
}
.faq-divider {
  background-color: #a4a4a4;
}
.faq-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-trigger {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  padding-right: 16px;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icons-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icon {
  width: 24px;
  height: 24px;
}
.faq-icon02 {
  width: 24px;
  height: 24px;
}
.faq-container03 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-text01 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 500;
}
.faq-link {
  text-decoration: underline;
}
.faq-divider1 {
  background-color: #a4a4a4;
}
.faq-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-trigger1 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icon04 {
  width: 24px;
  height: 24px;
}
.faq-icon06 {
  width: 24px;
  height: 24px;
}
.faq-container06 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-text06 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 500;
}
.faq-divider2 {
  background-color: #a4a4a4;
}
.faq-faq3 {
  width: 100%;
  align-items: flex-start;
}
.faq-trigger2 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icon08 {
  width: 24px;
  height: 24px;
}
.faq-icon10 {
  width: 24px;
  height: 24px;
}
.faq-container09 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-text07 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 500;
}
.faq-divider3 {
  background-color: #a4a4a4;
}
.faq-faq4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-trigger3 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icon12 {
  width: 24px;
  height: 24px;
}
.faq-icon14 {
  width: 24px;
  height: 24px;
}
.faq-container12 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-text08 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 500;
}
.faq-link1 {
  text-decoration: underline;
}
.faq-divider4 {
  background-color: #a4a4a4;
}
.faq-faq5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-trigger4 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-icon16 {
  width: 24px;
  height: 24px;
}
.faq-icon18 {
  width: 24px;
  height: 24px;
}
.faq-container15 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-text12 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 500;
}
.faq-divider5 {
  background-color: #a4a4a4;
}
.faq-content1 {
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.faq-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.faq-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}
.faq-container17:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-image {
  width: 54px;
  height: 54px;
  z-index: 100;
  object-fit: cover;
}
.faq-link2 {
  display: contents;
}
.faq-container18 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.faq-container18:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-image1 {
  width: 56px;
  height: 56px;
  z-index: 100;
  object-fit: cover;
}
.faq-link3 {
  display: contents;
}
.faq-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.faq-container19:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-image2 {
  width: 54px;
  height: 54px;
  z-index: 100;
  object-fit: cover;
  margin-right: 0px;
}
.faq-container20 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 600px;
  align-self: flex-start;
  align-items: center;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}
.faq-link4 {
  display: contents;
}
.faq-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: max-content;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 600px;
  min-width: auto;
  align-self: flex-start;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: 36px;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-theme-secondary2);
}
.faq-container21:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-image3 {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.faq-image4 {
  width: 16px;
  height: 16px;
  align-self: flex-start;
  object-fit: cover;
}
.faq-container23 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-text18 {
  font-style: normal;
  font-weight: 700;
}
.faq-text19 {
  font-style: normal;
  font-weight: 400;
}
.faq-link5 {
  display: contents;
}
.faq-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: max-content;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 600px;
  min-width: auto;
  align-self: flex-start;
  transition: 0.3s;
  border-radius: 36px;
  flex-direction: column;
  text-decoration: none;
  background-color: var(--dl-color-theme-secondary2);
}
.faq-container24:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-image5 {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.faq-image6 {
  width: 16px;
  height: 16px;
  align-self: flex-start;
  object-fit: cover;
}
.faq-container26 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-text20 {
  font-style: normal;
  font-weight: 700;
}
.faq-text21 {
  font-style: normal;
  font-weight: 400;
}
.faq-text22 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.faq-text23 {
  font-size: 16px;
}
.faq-text24 {
  font-style: normal;
  font-weight: 400;
}
.faq-text25 {
  font-style: normal;
  font-weight: 400;
}
.faq-text26 {
  font-style: normal;
  font-weight: 400;
}
.faq-text27 {
  color: #8c8c8c;
  font-style: normal;
  font-weight: 400;
}
.faq-text29 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 800;
}
.faq-text30 {
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 800;
}
.faq-text31 {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.faq-text32 {
  height: auto;
  font-size: 18px;
  margin-left: -48px;
  padding-top: var(--dl-space-space-unit);
  margin-right: 0px;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.faq-text33 {
  height: auto;
  font-size: 18px;
  margin-left: -48px;
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.faq-text34 {
  font-family: "Montserrat";
  font-weight: 600;
}
.faq-text35 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.faq-text36 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.faq-text37 {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.faq-text38 {
  height: auto;
  font-size: 18px;
  margin-left: -48px;
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.faq-text41 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.faq-text42 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.faq-text43 {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.faq-text44 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.faq-text45 {
  font-family: "Montserrat";
}
.faq-text46 {
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
}
.faq-text47 {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}


@media(max-width: 1321px) {
  .faq-container16 {
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
  }
  .faq-text22 {
    font-size: 16px;
  }
  .faq-text23 {
    font-size: 14px;
  }
  .faq-text31 {
    font-size: 20px;
  }
  .faq-text34 {
    font-size: 16px;
  }
  .faq-text35 {
    font-size: 16px;
  }
  .faq-text36 {
    font-size: 14px;
  }
  .faq-text37 {
    font-size: 20px;
  }
  .faq-text38 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-text41 {
    font-size: 14px;
  }
  .faq-text42 {
    font-size: 14px;
  }
  .faq-text43 {
    font-size: 20px;
  }
  .faq-text44 {
    font-size: 16px;
  }
  .faq-text46 {
    font-size: 16px;
  }
  .faq-text47 {
    font-size: 20px;
  }
}
@media(max-width: 1200px) {
  .faq-text32 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-text33 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-text38 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 991px) {
  .faq-faq8 {
    flex-direction: column;
  }
  .faq-container16 {
    justify-content: flex-start;
  }
  .faq-container17 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-container18 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-container19 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-container20 {
    margin-left: 0px;
  }
  .faq-container21 {
    margin-left: 0px;
  }
  .faq-container24 {
    margin-left: 0px;
  }
}
@media(max-width: 767px) {
  .faq-faq8 {
    flex-direction: column;
  }
  .faq-container20 {
    margin-left: 0px;
  }
  .faq-container21 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .faq-container24 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .faq-text22 {
    font-size: 14;
  }
  .faq-text29 {
    font-size: 24;
  }
  .faq-text30 {
    font-size: 24;
  }
  .faq-text32 {
    font-size: 16;
  }
  .faq-text33 {
    font-size: 16;
  }
  .faq-text34 {
    font-size: 14;
  }
  .faq-text35 {
    font-size: 14;
  }
  .faq-text38 {
    font-size: 16;
  }
  .faq-text44 {
    font-size: 14;
  }
  .faq-text45 {
    font-size: 20;
  }
  .faq-text46 {
    font-size: 14;
  }
}
@media(max-width: 479px) {
  .faq-faq8 {
    flex-direction: column;
  }
  .faq-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .faq-trigger {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .faq-trigger1 {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .faq-trigger2 {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .faq-trigger3 {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .faq-trigger4 {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .faq-container17 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .faq-container18 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .faq-container19 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .faq-container20 {
    margin-left: 0px;
  }
  .faq-container21 {
    margin-left: 0px;
  }
  .faq-container24 {
    margin-left: 0px;
  }
  .faq-text22 {
    font-size: 12;
  }
  .faq-text29 {
    font-size: 20;
  }
  .faq-text30 {
    font-size: 20;
  }
  .faq-text32 {
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-text33 {
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-text34 {
    font-size: 12;
  }
  .faq-text35 {
    font-size: 12;
  }
  .faq-text38 {
    font-size: 12;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .faq-text44 {
    font-size: 12;
  }
  .faq-text45 {
    font-size: 16;
  }
  .faq-text46 {
    font-size: 12;
  }
}
