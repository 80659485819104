.bottom-container {
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 18px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 18px;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.bottom-image {
  width: 114px;
  height: 83px;
  object-fit: cover;
}
.bottom-links {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.bottom-text {
  color: rgb(255, 255, 255);
}
.bottom-root-class-name {
  background-color: #1e1e1e;
}


@media(max-width: 1321px) {
  .bottom-container {
    padding-top: 18px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 18px;
  }
}
@media(max-width: 1200px) {
  .bottom-image {
    width: 96px;
    height: 69px;
  }
}
@media(max-width: 991px) {
  .bottom-image {
    width: 68px;
    height: 49px;
  }
  .bottom-links {
    margin-left: var(--dl-space-space-twounits);
  }
  .bottom-text {
    color: rgb(255, 255, 255);
  }
}
@media(max-width: 767px) {
  .bottom-image {
    width: 66px;
    height: 48px;
  }
}
@media(max-width: 479px) {
  .bottom-container {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .bottom-image {
    width: 60px;
    height: 43px;
  }
  .bottom-text {
    font-size: 12;
  }
}
