.title-header26 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: max-content;
  max-width: 2560px;
  min-height: 600px;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: initial;
  background-image: url("/2024-06-30_12.40.06%20(2)-1500w.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.title-container {
  flex: 0 0 auto;
  width: 100%;
  height: 120px;
  display: flex;
  margin-top: -1px;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgba(25, 25, 25, 0) 0.00%,rgb(25, 25, 25) 100.00%);
}
.title-content {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  max-width: auto;
  min-width: auto;
  align-self: start;
  max-height: auto;
  min-height: auto;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: space-between;
}
.title-link {
  display: contents;
}
.title-text2 {
  transition: 0.3s;
  text-decoration: none;
}

.title-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: flex-start;
  margin-bottom: -3px;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(25, 25, 25) 0.00%,rgba(25, 25, 25, 0) 100.00%);
}
.title-text3 {
  font-size: 18px;
  font-style: normal;
  margin-top: 0px;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: var(--dl-space-space-twounits);
}
.title-text4 {
  color: #000000;
  width: fit-content;
  height: auto;
  padding: var(--dl-space-space-oneandhalfunits);
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  font-family: "Montserrat";
  font-weight: 700;
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: rgb(255, 255, 255);
}
.title-text4:hover {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.title-text5 {
  font-style: normal;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 800;
}
.title-text6 {
  color: #2df059;
}

@media(max-width: 991px) {
  .title-container {
    height: 80px;
  }
  .title-content {
    flex-wrap: wrap;
    margin-left: var(--dl-space-space-twounits);
  }
  .title-container1 {
    height: 80px;
  }
  .title-text3 {
    font-size: 18;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
  }
  .title-text4 {
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .title-text5 {
    font-size: 36px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 800;
  }
  .title-text6 {
    color: #2df059;
  }
}
@media(max-width: 767px) {
  .title-content {
    width: 100%;
    margin-left: var(--dl-space-space-halfunit);
  }
  .title-text4 {
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-family: Montserrat;
    font-weight: 700;
  }
}
@media(max-width: 479px) {
  .title-header26 {
    min-height: 250px;
  }
  .title-container {
    height: 50px;
  }
  .title-content {
    width: 90%;
    height: 100%;
    align-items: flex-start;
    margin-right: 0px;
    flex-direction: column;
    justify-content: space-between;
  }
  .title-container1 {
    height: 50px;
  }
  .title-text3 {
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
  }
  .title-text4 {
    padding: var(--dl-space-space-unit);
    font-size: 12px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    font-family: Montserrat;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .title-text5 {
    font-size: 20;
  }
}
